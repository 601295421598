import { Component, Input, OnChanges, OnInit, signal, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

import { StateService } from '../state/state.service';

export type FeatureLimiterLimit = 'ultimate' | 'plus' | 'core';

/** Feature Limiter Component
 *
 * This component is used to limit the usage of a feature to a certain subscription.
 * If the feature is limited, a button will be shown that allows the user to upgrade.
 * When clicked, the upgrade function will be called.
 *
 * Must be used within a relative position context. Otherwise, the component will not work.
 *
 * @example
 * <div class="relative">
 *   <fini-feature-limiter [hasAccess]="true" tier="ultimate">
 *     ... feature ui ...
 *   </fini-feature-limiter>
 * </div>
 */
@Component({
  selector: 'fini-feature-limiter',
  templateUrl: './feature-limiter.component.html',
  styleUrl: './feature-limiter.component.scss',
})
export class FeatureLimiterComponent implements OnChanges, OnInit {
  constructor(
    private router: Router,
    private state: StateService,
  ) {}
  @Input() hasAccess = false;
  @Input() tier: FeatureLimiterLimit = 'ultimate';

  limitText = signal('');
  _isLimited = signal(false);

  upgrade() {
    this.router.navigate(['/upgrade']);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasAccess']) {
      this.processTier(this.tier);
      this._isLimited.set(!this.hasAccess);
    }
  }

  ngOnInit() {
    this.processTier(this.tier);
    this._isLimited.set(!this.hasAccess);
  }

  processTier(tier: FeatureLimiterLimit) {
    switch (tier) {
      case 'ultimate':
        this.limitText.set('Ultimate Subscription');
        break;
      case 'plus':
        this.limitText.set('Plus Subscription');
        break;
      case 'core':
        this.limitText.set('Core Subscription');
        break;
    }
  }
}
