<div class="feature-limited">
  <ng-content></ng-content>
  @if (_isLimited()) {
    <div class="overlay">
      <div
        class="d-flex flex-row gap-2 align-items-center justify-content-center"
      >
        <span>Sorry, this feature is limited to {{ limitText() }}.</span>
      </div>
      <button class="button primary small" (click)="upgrade()">
        Upgrade
        <i class="material-symbols-outlined"> upgrade </i>
      </button>
    </div>
  }
</div>
