import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureLimiterComponent } from './feature-limiter.component';

@NgModule({
  declarations: [FeatureLimiterComponent],
  imports: [CommonModule],
  exports: [FeatureLimiterComponent],
})
export class FeatureLimiterModule {}
